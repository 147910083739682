import React from 'react';
import SquareImg from './squareImage';

const TripleImage = ({image1, image2, image3, mode}) => {
    if (mode === 1){
        return(
            <div className="relative h-0" style={{paddingBottom: 'calc(100% / calc(6/4))'}}>
                <div className="w-7/12 absolute" style={{left:'40%', transform:'translateX(-50%)'}}>
                    <SquareImg src={image1} />
                </div>
                <div className="w-2/12 absolute"  style={{ left:'0%', bottom:'0%' }}>
                    <SquareImg src={image2} />
                </div>
                <div className="w-4/12 absolute" style={{ right:'0%', top:'25%' }} >
                    <SquareImg src={image3}   />
                </div>
            </div>
        )
    }else if(mode===2){
        return(
            <div className="relative h-0" style={{paddingBottom: 'calc(100% / calc(6/4))'}}>
                <div className="w-7/12 absolute" style={{top:'2rem' ,left:'40%', transform:'translateX(-50%)'}}>
                    <SquareImg src={image1} />
                </div>
                <div className="w-2/12 absolute"  style={{ left:'0%', top:'0%' }}>
                    <SquareImg src={image2} />
                </div>
                <div className="w-4/12 absolute" style={{ right:'5%', bottom:'0%' }} >
                    <SquareImg src={image3}   />
                </div>
            </div>
        )
    }else{
        return(
            <div className="relative h-0" style={{paddingBottom: 'calc(100% / calc(6/4))'}}>
                <div className="w-7/12 absolute" style={{top:'2rem' ,left:'40%', transform:'translateX(-50%)'}}>
                    <SquareImg src={image1} />
                </div>
                <div className="w-2/12 absolute"  style={{ left:'0%', top:'60%' }}>
                    <SquareImg src={image2} />
                </div>
                <div className="w-4/12 absolute" style={{ right:'5%', top:'0%' }} >
                    <SquareImg src={image3}   />
                </div>
            </div>
        )
    }
}

export default TripleImage;