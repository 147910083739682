import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

import Header from "./header";
import Prefooter from './prefooter';
import Footer from './footer';

function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="flex flex-col font-sans ">
        <div className="bg-darkRed py-4">
            <div className="w-3/4 text-center mx-auto">
                <p className="text-white text-center">
                    Feeling worried or struggling to cope during the coronavirus pandemic? <br/>The Beyond Blue Coronavirus Mental Wellbeing Support Service can help 24/7. Call 1800 512 348 or visit the <a className="underline" href="https://coronavirus.beyondblue.org.au/" target="_blank">website here</a>
                </p>
            </div>
        </div>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main>
        {children}
      </main> 

      <Prefooter></Prefooter>
      <Footer></Footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
