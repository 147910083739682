import React, { useState } from 'react';
import logo from '../images/phn-logo.svg';

const Footer = () => {
  const [disclaimer, setDisclaimer] = useState(false);

  return  disclaimer ? (
    <div>
        <div className="fixed top-0 w-full h-full bg-red z-20 m-auto">
        <div className="bg-black opacity-40 w-full h-full z-10 absolute top-0 left-0"></div>
          <div className="container mx-auto h-full z-30 relative">
            <div className="flex justify-center h-full">
              <div className="w-full md:w-6/12 m-auto bg-light relative">
              <button name="close" className="absolute right-0 top-0 z-40 px-4 py-2 bg-dark text-white" onClick={() => {setDisclaimer(!disclaimer)}}>Close</button>
                <p className="px-8 py-16">While the Australian Government Department of Health has contributed to the funding of this website, the information on this website does not necessarily reflect the views of the Australian Government and is not advice that is provided, or information that is endorsed, by the Australian Government. The Australian Government is not responsible in negligence or otherwise for any injury, loss or damage however arising from the use of or reliance on the information provided on this website.</p>
              </div>
              </div>
          </div>
        </div>
    <footer className="bg-dark">
    <div className="container mx-auto py-16">
     <nav className="flex flex-col items-center mx-auto">
          <img
            className="mb-12 "
            src={logo}
            alt="Brisbane North PHN"
          />
        <div className="w-full md:w-6/12  text-center">
          <p className="text-white px-4 m-auto mb-4 leading-relaxed text-sm">
            Brisbane North PHN wishes to acknowledge the experience and expertise of the Brisbane North Suicide Prevention Network, delegates of the Peer Participation in Mental Health Services (PIMMS) network and health professionals in developing the Reasons to Stay campaign.
          </p>
          <p className="text-white px-4 m-auto mb-4 leading-relaxed text-sm">
            Brisbane North PHN acknowledges the traditional custodians past and present on whose land we walk, we work and we live. 
          </p>
          <p className="text-white px-4 m-auto mb-4 leading-relaxed text-sm">
          This activity is supported by funding from the Australian Government under the PHN Program. <span className="no-underline text-white cursor-pointer" onClick={() => setDisclaimer(!disclaimer)}>View disclaimer.</span>
          </p>
        </div>
      </nav>
    </div>
  </footer>
  </div>
  ) : (
    <footer className="bg-dark">
    <div className="container mx-auto py-16">
     <nav className="flex flex-col items-center mx-auto">
          <img
            className="mb-12 "
            src={logo}
            alt="Brisbane North PHN"
          />
        <div className="w-full md:w-3/4  text-center">
        <p className="text-white px-4 m-auto mb-4 leading-relaxed text-sm">
            Brisbane North PHN wishes to acknowledge the experience and expertise of the Brisbane North Suicide Prevention Network, delegates of the Peer Participation in Mental Health Services (PPIMS) network and health professionals in developing the Reasons to Stay campaign.
          </p>
          <p className="text-white px-4 m-auto mb-4 leading-relaxed text-sm">
            Brisbane North PHN acknowledges the traditional custodians past and present on whose land we walk, we work and we live. 
          </p>
          <p className="text-white px-4 m-auto mb-4 leading-relaxed text-sm">
          This activity is supported by funding from the Australian Government under the PHN Program. <span className="underline text-white  cursor-pointer" onClick={() => setDisclaimer(!disclaimer)}>View disclaimer.</span>
          </p>
        </div>
      </nav>
    </div>
  </footer>

  )
}

export default Footer;