import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";

import title from '../images/title.svg';

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <nav className="bg-light">
    <div className="container mx-auto">
      <div className="flex flex-wrap items-center justify-between p-4 md:px-0 md:py-8">
        <Link to="/" className="flex items-center no-underline text-white">
          <img
              src={title}
              alt="Find your reasons to stay"
          />
        </Link>
        <div
          className='md:block md:flex md:items-center w-full md:w-auto'
        >
          <div className="text-sm  cursor-pointer">
            <Link
              to="#prefooter"
              className="inline-block mt-4 md:mt-0 no-underline bg-white px-8 py-3 rounded-sm font-bold"
            >
              Find support now
            </Link>
          </div>
        </div>
      </div>
      </div>
    </nav>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
