import React from 'react';
import phone from '../images/phone.svg';
import { Link } from "gatsby";

const PreFooter = () => (
  <div id="prefooter">
    <div className="bg-light">
      <div className="container mx-auto py-12">
        <div className="flex items-center">
          <div className="w-full md:w-12/12 px-2 text-center">
            <p className="text-xl font-bold mb-4 ">
              If you or someone you are with is in immediate, life-threatening danger:
            </p>
            <p className="text-xl">
              Call Triple Zero (<a className="underline" href="tel:000">000</a>) or go immediately to your <a target="_blank" rel="noopener noreferrer" href="https://www.mymentalhealth.org.au/page/services/community-services/sp-care-pathways/suicidal-crisis/emergency-department/" className="underline">local hospital emergency department</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    
  <div className="bg-primary">
    <div className="container mx-auto py-16">
    <div className="flex flex-col items-center">
    <div className="w-full md:w-8/12 px-2 md:pr-12 mb-8">
      <p className="text-white text-2xl leading-relaxed text-center">You can access immediate support by contacting:</p>
    </div>
    <div className="w-full md:w-8/12 px-2">
     <div className="flex flex-col md:flex-row mx-auto md:mb-4 ">
        <div className="w-full md:w-6/12 pb-2 md:pb-0 md:px-2">
          <div className="p-6 bg-secondary text-white h-full rounded">
            <a target="_blank" rel="noopener noreferrer" href="https://www.suicidecallbackservice.org.au/phone-and-online-counselling/" className="text-xl font-bold block mb-2 cursor-pointer">Suicide Call Back Service</a>
            <div className="mb-2 text-lg flex justify-center"><img alt="phone icon" className="inline pr-2 m-auto"src={phone}/><a className="flex-grow" href="tel:1300659467">1300 659 467</a></div>
            <p className="mb-2  leading-relaxed">Telephone, online and video chat (24 hours a day, seven days a week)</p>
          </div>
        </div>
        <div className="w-full md:w-6/12 pb-2 md:pb-0 md:px-2">
          <div className="p-6 bg-secondary text-white  h-full rounded">
            <a target="_blank" rel="noopener noreferrer" href="https://www.lifeline.org.au/get-help/get-help-home" className="text-xl font-bold block mb-2 cursor-pointer">Lifeline</a>
            <div className="mb-2 text-lg flex justify-center"><img alt="phone icon" className="inline pr-2 m-auto"src={phone}/><a className="flex-grow" href="tel:131114">13 11 14</a></div>
            <p className="mb-2 leading-relaxed">Telephone support (24 hours a day, seven days a&nbsp;week)</p>
            <p className="mb-2 leading-relaxed">Online chat and text chat (nightly, seven days a&nbsp;week)</p>
          </div>  
        </div>
      </div>
      <div className="flex mx-auto flex-col md:flex-row">
        <div className="w-full md:w-6/12 pb-2 md:pb-0 md:px-2">
          <div className="p-6 bg-secondary text-white h-full rounded">
            <a target="_blank" rel="noopener noreferrer" href="https://www.beyondblue.org.au/get-support/get-immediate-support" className="text-xl font-bold block mb-2 cursor-pointer">Beyond Blue Support Service</a>
            <div className="mb-2 text-lg flex justify-center"><img alt="phone icon" className="inline pr-2 m-auto"src={phone}/><a className="flex-grow" href="tel:1300224636">1300 224 636</a></div>
            <p className="mb-2 leading-relaxed">Telephone support (24 hours a day, seven days a&nbsp;week)</p>
            <p className="mb-2 leading-relaxed">Online chat (nightly, seven days a week)</p>
          </div>
        </div>
        <div className="w-full md:w-6/12 md:px-2 ">
          <div className="p-6 bg-secondary text-white  h-full rounded">
            <a target="_blank" rel="noopener noreferrer" href="https://www.qld.gov.au/health/mental-health/help-lines/1300-mh-call" className="text-xl font-bold block mb-2 cursor-pointer">1300 MH Call: Mental Health Access&nbsp;Line</a>
            <div className="mb-2 text-lg flex justify-center"><img alt="phone icon" className="inline pr-2 m-auto"src={phone}/><a className="flex-grow"  href="tel:1300642255">1300 64 2255</a></div>
            <p className="mb-2  leading-relaxed">For advice and information in a mental health emergency or crisis and to link you to your closest Queensland public mental health service. Available 24 hours a day, seven days a week.</p>
          </div>
        </div>
      </div>
      </div>
    </div>
    </div>
    </div>
    <div className="bg-white">
      <div className="container mx-auto py-12">
        <div className="flex items-center">
          <div className="w-full md:w-12/12 px-2 text-center">
            <p className="text-xl">
              <Link to="/videos" className="underline">View and share the campaign videos</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
)

export default PreFooter;