import React from 'react';
import chevronRight from '../images/chevron-right.svg';
import { Link } from "gatsby";


const ChevronLink = ({children, to}) => (
    <Link to={to} className="relative flex flex items-center group w-full md:w-8/12 lg:w-6/12 min-h-24  border border-light hover:border-primary cursor-pointer rounded py-4 px-4 sm:px-8 m-2 transition-border transition-250">
        <p className="text-lg md:text-xl pr-8 md:pr-32">{children}</p>
        <img
            src={chevronRight}
            className="absolute w-4 right-0 top-0 mt-8 mr-4 md:mr-8 hover:mr-4 opacity-40 group-hover:opacity-100 transition-opacity transition-250"
            alt="Chevron"
        />
    </Link>
)

export default ChevronLink;